// ClientEngagmentsCatalogPage.tsx

import React from 'react';
import { Content } from '@backstage/core-components';
import { EntityTable, EntityRefLink } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { TableColumn } from '@backstage/core-components';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Typography,
  Chip,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { Search as SearchIcon, Clear as ClearIcon } from '@material-ui/icons';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  activeChip: {
    backgroundColor: '#4caf50', // green
    color: 'white',
  },
  inactiveChip: {
    backgroundColor: '#f44336', // red
    color: 'white',
  },
  filterGrid: {
    marginBottom: theme.spacing(4), // Add bottom margin to filter grid
  },
  tableContainer: {
    marginTop: theme.spacing(4), // Add top margin to table
  },
  searchWrapper: {
    position: 'relative',
    width: '100%',
  },
  searchProgress: {
    position: 'absolute',
    right: '48px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  searchIcon: {
    position: 'absolute',
    right: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  },
  filterWrapper: {
    position: 'relative',
  },
  filterProgress: {
    position: 'absolute',
    right: '40px',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
  },
}));

// Utility function to format the type string
const formatType = (typeValue: any): string => {
  if (typeof typeValue !== 'string') {
    typeValue = String(typeValue ?? '');
  }

  return typeValue
    .replace(/_/g, ' ')
    .toLowerCase()
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// Utility function to check if an engagement is active
const isEngagementActive = (entity: Entity): boolean => {
  const startDate = entity.metadata.annotations?.['monday.com.field-dateStart'];
  const endDate = entity.metadata.annotations?.['monday.com.field-dateEnd'];
  
  if (!startDate || !endDate) return false;

  const currentDate = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  return currentDate >= start && currentDate <= end;
};

const columns: TableColumn<Entity>[] = [
  {
    title: 'Engagement',
    field: 'metadata.name',
    highlight: true,
    render: (entity: Entity) => <EntityRefLink entityRef={entity} />,
  },
  {
    title: 'Customer',
    field: 'metadata.annotations["monday.com.field-customerName"]',
    render: (entity: Entity) => formatType(entity.metadata.annotations?.["monday.com.field-customerName"]),
  },
  {
    title: 'Type',
    field: 'metadata.annotations["monday.com.field-projectType"]',
    render: (entity: Entity) => formatType(entity.metadata.annotations?.["monday.com.field-projectType"]),
  },
  {
    title: 'Is Active',
    field: 'spec.owner',
    render: (entity: Entity) => {
      const classes = useStyles();
      try {
        const isActive = isEngagementActive(entity);
        return (
          <Chip
            label={isActive ? 'Yes' : 'No'}
            className={isActive ? classes.activeChip : classes.inactiveChip}
            size="small"
          />
        );
      } catch (error) {
        console.error('Error checking engagement status:', error);
        return <Chip label="No" className={classes.inactiveChip} size="small" />;
      }
    },
  },
];

interface EntityTableComponentProps {
  searchQuery: string;
  customerFilter: string;
  entitiesPerPage: number;
  activeFilter: string;
  onCustomersUpdate: (customers: string[]) => void;
  onFilteringStatusChange: (isFiltering: boolean) => void;
}

const EntityTableComponent: React.FC<EntityTableComponentProps> = ({
  searchQuery,
  customerFilter,
  entitiesPerPage,
  activeFilter,
  onCustomersUpdate,
  onFilteringStatusChange,
}) => {
  const catalogApi = useApi(catalogApiRef);
  const [entities, setEntities] = React.useState<Entity[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  // Filtered entities state
  const [filteredEntities, setFilteredEntities] = React.useState<Entity[]>([]);
  // Pagination state
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  // Fetch initial entities
  React.useEffect(() => {
    let isMounted = true;

    const fetchEntities = async () => {
      try {
        const response = await catalogApi.getEntities();
        if (isMounted) {
          const initialEntities = response.items.filter(
            (entity: Entity) => entity.kind === 'Resource'
          );
          setEntities(initialEntities);
          setFilteredEntities(initialEntities); // Set initial filtered entities
        }
      } catch (error) {
        console.error('Failed to fetch entities:', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchEntities();
    return () => { isMounted = false; };
  }, [catalogApi]);

  // Apply filters
  React.useEffect(() => {
    onFilteringStatusChange(true);
    const timer = setTimeout(() => {
      let result = [...entities];

      // Apply filters...
      if (customerFilter && customerFilter !== 'All') {
        result = result.filter(
          entity => entity.metadata.annotations?.['monday.com.field-customerName'] === customerFilter
        );
      }

      if (searchQuery) {
        const lowerSearchQuery = searchQuery.toLowerCase();
        result = result.filter(entity =>
          entity.metadata.name.toLowerCase().includes(lowerSearchQuery) ||
          entity.metadata.title?.toLowerCase().includes(lowerSearchQuery)
        );
      }

      if (activeFilter !== 'All') {
        result = result.filter(entity => {
          const isActive = isEngagementActive(entity);
          return activeFilter === 'Active' ? isActive : !isActive;
        });
      }

      setFilteredEntities(result);
      onFilteringStatusChange(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [entities, searchQuery, customerFilter, activeFilter, onFilteringStatusChange]);

  // Update customers when entities are filtered
  React.useEffect(() => {
    const uniqueCustomers = new Set<string>();
    
    filteredEntities.forEach((entity: Entity) => {
      const customerName = entity.metadata.annotations?.['monday.com.field-customerName'];
      if (customerName) {
        uniqueCustomers.add(customerName);
      }
    });

    onCustomersUpdate(Array.from(uniqueCustomers).sort());
  }, [filteredEntities, onCustomersUpdate]);

  // Calculate pagination
  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = filteredEntities.slice(indexOfFirstEntity, indexOfLastEntity);
  const totalPages = Math.ceil(filteredEntities.length / entitiesPerPage);

  // Pagination controls
  const renderPaginationControls = () => {
    return (
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: '16px' }}
      >
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(prev => prev - 1)}
          >
            Previous
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            Page {currentPage} of {totalPages}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            disabled={currentPage === totalPages || totalPages === 0}
            onClick={() => setCurrentPage(prev => prev + 1)}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    );
  };

  if (loading) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="400px"
      >
        <CircularProgress 
          size={40}
          color="primary"
        />
      </Box>
    );
  }

  if (filteredEntities.length === 0) {
    return <div>No entities found matching the current filters.</div>;
  }

  return (
    <>
      <EntityTable
        title="Engagement Catalog"
        entities={currentEntities}
        columns={columns}
      />
      {renderPaginationControls()}
    </>
  );
};

const EngagementCatalogPage: React.FC = () => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [isSearching, setIsSearching] = React.useState(false);
  const [customerFilter, setCustomerFilter] = React.useState<string>('All');
  const [entitiesPerPage, setEntitiesPerPage] = React.useState<number>(10);
  const [activeFilter, setActiveFilter] = React.useState<string>('All');
  const [isFilteringStatus, setIsFilteringStatus] = React.useState(false);
  const [customers, setCustomers] = React.useState<string[]>([]);
  const catalogApi = useApi(catalogApiRef);
  const handleCustomersUpdate = React.useCallback((newCustomers: string[]) => {
    setCustomers(newCustomers);
  }, []);
  // Handle filtering status change
  const handleFilteringStatusChange = React.useCallback((isFiltering: boolean) => {
    setIsFilteringStatus(isFiltering);
  }, []);

  // Add debounce for search
  React.useEffect(() => {
    setIsSearching(true);
    const timer = setTimeout(() => {
      setIsSearching(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  // Add debounce for status filter
  React.useEffect(() => {
    setIsFilteringStatus(true);
    const timer = setTimeout(() => {
      setIsFilteringStatus(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [activeFilter]);

  // Fetch and process unique customer names
  React.useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await catalogApi.getEntities();
        const uniqueCustomers = new Set<string>();
        
        response.items.forEach((entity: Entity) => {
          const customerName = entity.metadata.annotations?.['monday.com.field-customerName'];
          if (customerName) {
            uniqueCustomers.add(customerName);
          }
        });

        setCustomers(Array.from(uniqueCustomers).sort());
      } catch (error) {
        console.error('Failed to fetch customers:', error);
      }
    };

    fetchCustomers();
  }, [catalogApi]);

  return (
    <Content>
      <Grid container spacing={2} alignItems="flex-start" className={classes.filterGrid}>
      <Grid item xs={12} sm={6} md={3}>
          <div className={classes.searchWrapper}>
            <TextField
              label="Search by Name"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              InputProps={{
                endAdornment: searchQuery && (
                  <ClearIcon
                    className={classes.searchIcon}
                    onClick={() => setSearchQuery('')}
                  />
                ),
              }}
            />
            {isSearching && (
              <CircularProgress
                size={20}
                className={classes.searchProgress}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="customer-filter-label">Customer</InputLabel>
            <Select
              labelId="customer-filter-label"
              label="Customer"
              value={customerFilter}
              onChange={e => setCustomerFilter(e.target.value as string)}
            >
              <MenuItem value="All">All Customers</MenuItem>
              {customers.map(customer => (
                <MenuItem key={customer} value={customer}>
                  {customer}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className={classes.filterWrapper}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="active-filter-label">Status</InputLabel>
              <Select
                labelId="active-filter-label"
                label="Status"
                value={activeFilter}
                onChange={e => setActiveFilter(e.target.value as string)}
              >
                <MenuItem value="All">All Status</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
            {isFilteringStatus && (
              <CircularProgress
                size={20}
                className={classes.filterProgress}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="entities-per-page-label">
              Entities per page
            </InputLabel>
            <Select
              labelId="entities-per-page-label"
              label="Entities per page"
              value={entitiesPerPage.toString()}
              onChange={e =>
                setEntitiesPerPage(parseInt(e.target.value as string, 10))
              }
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      
      <Box className={classes.tableContainer}>
        <EntityTableComponent
          searchQuery={searchQuery}
          customerFilter={customerFilter}
          entitiesPerPage={entitiesPerPage}
          activeFilter={activeFilter}
          onCustomersUpdate={handleCustomersUpdate}
          onFilteringStatusChange={handleFilteringStatusChange}
        />
      </Box>
    </Content>
  );
};

export default EngagementCatalogPage;

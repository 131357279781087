import React from 'react';
import { makeStyles } from '@material-ui/core';
import MyCustomLogoFull from './img/logoIcon.png';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 25,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return <img className={classes.svg} src={MyCustomLogoFull} alt="Logo" />;
};

export default LogoIcon;

// src/components/EntityPage.tsx

import React from 'react';
import { Button, Grid, Card, CardContent, Typography, Box } from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
} from '@backstage/plugin-catalog';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EmptyState } from '@backstage/core-components';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  EntityRelation,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';

import {
  Avatar, 
  Paper, 
  makeStyles, 
  Theme
} from '@material-ui/core';

// Import necessary icons from the catalog plugin 
import {
  Description as DescriptionIcon,
  Business as BusinessIcon,
  Timeline as TimelineIcon,
  Category as CategoryIcon,
  Person as PersonIcon,
  AttachMoney as MoneyIcon,
  Schedule as ScheduleIcon,
  AccountTree as AccountTreeIcon,
} from '@material-ui/icons';

import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
// Bazaar plugin imports for the EntityPage
import { EntityBazaarInfoCard, isBazaarAvailable } from '@backstage-community/plugin-bazaar';

// Import necessary hooks and components
import { useEntity } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';

import { ConsultantPage } from './entities/ConsultantPage';

import { 
  EntityRefLink, 
  catalogApiRef 
} from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';

const ConsultantCard = ({ consultantName }: { consultantName: string }) => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  
  const { value: userEntity } = useAsync(async () => {
    if (!consultantName) return null;
    
    try {
      const result = await catalogApi.getEntities({
        filter: {
          kind: 'User',
          'spec.profile.displayName': consultantName
        }
      });
      
      return result.items[0] || null;
    } catch (error) {
      console.error('Failed to fetch user:', error);
      return null;
    }
  }, [consultantName]);

  return (
    <Paper elevation={2} className={classes.card}>
      <Box className={classes.cardContent}>
        <Box className={classes.iconHeader}>
          <PersonIcon className={classes.icon} />
          <Typography variant="h6" className={classes.title}>
            Consultant
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          {userEntity && userEntity.spec?.profile && typeof userEntity.spec.profile === 'object' && 'picture' in userEntity.spec.profile && (
            <Avatar
              alt={consultantName}
              src={(userEntity.spec.profile as { picture: string }).picture}
              style={{ 
                marginRight: '8px',
                width: 40,
                height: 40
              }}
            />
          )}
          <Typography variant="body1" className={classes.value}>
            {userEntity ? (
              <EntityRefLink entityRef={userEntity} />
            ) : (
              consultantName || 'No consultant specified'
            )}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

// Update styles
const useStyles = makeStyles((theme: Theme) => ({
  section: {
    padding: theme.spacing(3), // Increased padding
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginBottom: theme.spacing(2), // Add margin between sections
    },
  },
  title: {
    marginBottom: theme.spacing(2), // Increased margin below titles
    color: theme.palette.text.primary,
    fontWeight: 500, // Make titles more prominent
  },
  content: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1), // Add margin below content
  },
  timeline: {
    marginTop: theme.spacing(3), // Increased margin above timeline
    marginBottom: theme.spacing(1),
  },
  peopleGrid: {
    marginTop: theme.spacing(4), // Add margin above people grid
  },
  card: {
    height: '100%',
    transition: 'transform 0.2s, box-shadow 0.2s',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: theme.shadows[4],
    },
  },
  typeLabel: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
    fontSize: '0.875rem',
    boxShadow: theme.shadows[1],
    zIndex: 1,
  },
  cardContent: {
    position: 'relative',
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  iconHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  value: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

// Define helper functions to check entity kind and type
const isSystemType = (type: string) => (entity: Entity) =>
  entity.kind.toLocaleLowerCase('en-US') === 'system' &&
  entity.spec?.type === type.toLocaleLowerCase('en-US');

const isResourceType = (type: string) => (entity: Entity) =>
  entity.kind.toLocaleLowerCase('en-US') === 'resource' &&
  entity.spec?.type === type.toLocaleLowerCase('en-US');

// First add date formatting utility
const formatDate = (dateString: string | undefined): string => {
  if (!dateString) return 'Not specified';
  
  try {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date';
  }
};

// DateRangeProgress component
const DateRangeProgress = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
  const calculateProgress = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const now = new Date();

    if (now < start) return 0;
    if (now > end) return 100;

    const total = end.getTime() - start.getTime();
    const current = now.getTime() - start.getTime();
    return Math.round((current / total) * 100);
  };

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <Typography variant="body2">{startDate}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Box sx={{ width: '100%', bgcolor: '#e0e0e0', borderRadius: 1 }}>
            <Box
              sx={{
                width: `${calculateProgress()}%`,
                bgcolor: 'primary.main',
                height: 8,
                borderRadius: 1,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">{endDate}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

// Define custom panels
const CustomerDescriptionPanel = () => {
  const { entity } = useEntity();
  const description = entity?.metadata?.description;

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6">Customer Description</Typography>
        <Typography>{description}</Typography>
      </CardContent>
    </Card>
  );
};

const EndOfLifePanel = () => {
  const { entity } = useEntity();
  const endOfLife = entity?.metadata?.labels?.['end-of-life'];

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6">End of Life</Typography>
        <Typography>{endOfLife || 'No end of life specified'}</Typography>
      </CardContent>
    </Card>
  );
};

const BudgetDescriptionPanel = () => {
  const classes = useStyles();
  const { entity } = useEntity();
  const description = entity?.metadata?.description;
  const budget = entity.metadata.annotations?.['monday.com.field-budget'] ?? 'No budget specified';
  const type = entity.metadata.annotations?.['monday.com.field-type'] ?? 'No budget type specified';
  const budgetYield = entity.metadata.annotations?.['monday.com.field-yield'] ?? 'No yield specified';

  return (
    <Paper elevation={2} className={classes.card}>
      <Box className={classes.cardContent}>
        <Typography className={classes.typeLabel}>
          Type: {type}
        </Typography>
        <CardContent className={classes.section}>
          <Box display="flex" alignItems="center" mb={2}>
            <DescriptionIcon className={classes.icon} />
            <Typography variant="h6" className={classes.title}>
              Description
            </Typography>
          </Box>
          <Typography variant="body1" className={classes.content}>{description}</Typography>
        </CardContent>
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CardContent className={classes.section}>
              <Box display="flex" alignItems="center" mb={2}>
                <MoneyIcon className={classes.icon} />
                <Typography variant="h6" className={classes.title}>
                  Budget
                </Typography>
              </Box>
              <Typography variant="body1" className={classes.content}>{budget} Euro</Typography>
            </CardContent>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <CardContent className={classes.section}>
              <Box display="flex" alignItems="center" mb={2}>
                <AccountTreeIcon className={classes.icon} />
                <Typography variant="h6" className={classes.title}>
                  Yield
                </Typography>
              </Box>
              <Typography variant="body1" className={classes.content}>{budgetYield}</Typography>
            </CardContent>
          </Grid>
        </Grid>

        <CardContent>
          <Box display="flex" alignItems="center" mb={2}>
            <TimelineIcon className={classes.icon} />
            <Typography variant="h6" className={classes.title}>
              Progress
            </Typography>
          </Box>
          <DateRangeProgress 
            startDate={entity.metadata.annotations?.['monday.com.field-dateStart'] || ''} 
            endDate={entity.metadata.annotations?.['monday.com.field-dateEnd'] || ''} 
          />
        </CardContent>
      </Box>
    </Paper>
  );
};

const EngagementDescriptionPanel = () => {
  // Use the useStyles hook
  const classes = useStyles();
  const { entity } = useEntity();
  // Get information from the entity
  const description = entity?.metadata?.description;
  const customer = entity.metadata.annotations?.['monday.com.field-customerName'] ? entity.metadata.annotations?.['monday.com.field-customerName'] : 'No customer specified';
  const projectType = entity.metadata.annotations?.['monday.com.field-projectType'] ? entity.metadata.annotations?.['monday.com.field-projectType'] : 'No project type specified';
  
  return (
    <Paper elevation={2} className={classes.card}>
      <Box className={classes.cardContent}>
        <Typography className={classes.typeLabel}>
          Type: {projectType}
        </Typography>
        
        <CardContent className={classes.section}>
          <Box display="flex" alignItems="center" mb={2}>
            <DescriptionIcon color="primary" style={{ marginRight: '8px' }} />
            <Typography variant="h6" className={classes.title}>
              Description
            </Typography>
          </Box>
          <Typography variant="body1" className={classes.content}>
            {description}
          </Typography>
        </CardContent>
      
        <CardContent className={classes.section}>
          <Box display="flex" alignItems="center" mb={2}>
            <BusinessIcon color="primary" style={{ marginRight: '8px' }} />
            <Typography variant="h6" className={classes.title}>
              Customer
            </Typography>
          </Box>
          <Typography variant="body1" className={classes.content}>
            {customer}
          </Typography>
        </CardContent>

        <CardContent className={classes.section}>
          <Box display="flex" alignItems="center" mb={2}>
            <TimelineIcon color="primary" style={{ marginRight: '8px' }} />
            <Typography variant="h6" className={classes.title}>
              Project Timeline
            </Typography>
          </Box>
          <Box className={classes.timeline}>
            <DateRangeProgress 
              startDate={entity.metadata.annotations?.['monday.com.field-dateStart'] || ''} 
              endDate={entity.metadata.annotations?.['monday.com.field-dateEnd'] || ''} 
            />
          </Box>
        </CardContent>
      </Box>
    </Paper>
  );
};

const PeopleAssignedPanel = () => {
  const classes = useStyles();
  const { entity } = useEntity();
  const consultantName = entity.metadata.annotations?.['monday.com.field-consultantName'] ? entity.metadata.annotations?.['monday.com.field-consultantName'] : 'No consultant specified';
  const consultantTarrif = entity.metadata.annotations?.['monday.com.field-consultantTariff'] ? entity.metadata.annotations?.['monday.com.field-consultantTariff'] : 'No tarrif specified';
  const hours = entity.metadata.annotations?.['monday.com.field-consultantHours'] ? entity.metadata.annotations?.['monday.com.field-consultantHours'] : 'No hours specified';

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        {ConsultantCard({ consultantName })}
      </Grid>
      <Grid item xs={12} sm={4}>
        <Paper elevation={2} className={classes.card}>
          <Box className={classes.cardContent}>
            <Box className={classes.iconHeader}>
              <MoneyIcon className={classes.icon} />
              <Typography variant="h6" className={classes.title}>
                Tariff
              </Typography>
            </Box>
            <Typography variant="body1" className={classes.value}>
              {consultantTarrif}
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Paper elevation={2} className={classes.card}>
          <Box className={classes.cardContent}>
            <Box className={classes.iconHeader}>
              <ScheduleIcon className={classes.icon} />
              <Typography variant="h6" className={classes.title}>
                Hours
              </Typography>
            </Box>
            <Typography variant="body1" className={classes.value}>
              {hours}
            </Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    {/* ...CI/CD content... */}
    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>

    <Grid item md={4} xs={12}>
      <EntityLinksCard />
    </Grid>
    <Grid item md={8} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={isBazaarAvailable}>
        <Grid item sm={6}>
          <EntityBazaarInfoCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);

// Define customerSystemPage
const customerSystemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        {/* Customer Description Panel */}
        <Grid item md={6}>
          <CustomerDescriptionPanel />
        </Grid>
        {/* Relationship Panel */}
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        {/* End Of Life Panel */}
        <Grid item md={12}>
          <EndOfLifePanel />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/ideas" title="Ideas">
      <EntitySwitch>
        <EntitySwitch.Case if={isBazaarAvailable}>
          <Grid item sm={6}>
            <EntityBazaarInfoCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </EntityLayout.Route>
  </EntityLayout>
);

const budgetResourcePage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <BudgetDescriptionPanel />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

// Define assignmentResourcePage
const engagementResourcePage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={4} alignItems="stretch">
        {entityWarningContent}
        {/* Assignment Description Panel */}
        <Grid item md={6}>
          <EngagementDescriptionPanel />
        </Grid>
        {/* Relationship Panel */}
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        {/* People Assigned Panel */}
        <Grid item md={12}>
          <PeopleAssignedPanel />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    
    <EntityLayout.Route path="/ideas" title="Ideas">
      <EntitySwitch>
        <EntitySwitch.Case if={isBazaarAvailable}>
          <Grid item sm={6}>
            <EntityBazaarInfoCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </EntityLayout.Route>
  </EntityLayout>
);

const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityMembersListCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityLinksCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid item md={8}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

// Optional: Define a generic resourcePage if needed
const resourcePage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    {/* Custom cases for specific entity types */}
    <EntitySwitch.Case if={isResourceType('customer')} children={customerSystemPage} />
    <EntitySwitch.Case if={isResourceType('engagement')} children={engagementResourcePage} />
    <EntitySwitch.Case if={isComponentType('budget')} children={budgetResourcePage} />
    {/* Existing cases */}
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={<ConsultantPage />} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
    <EntitySwitch.Case if={isKind('resource')} children={resourcePage} />

    {/* Default case */}
    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

// src/hooks/useSharePoint.ts
import { Client } from '@microsoft/microsoft-graph-client';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";

const msalConfig = {
  auth: {
    clientId: process.env.AZURE_CLIENT_ID || '',
    authority: `https://login.microsoftonline.com/${process.env.AZURE_TENANT_ID}`,
    redirectUri: window.location.origin, // Add redirect URI
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  }
};

export const useSharePoint = () => {
    const msalInstance = new PublicClientApplication({
        ...msalConfig,
        system: {
          allowNativeBroker: false, // Disable native broker
          windowHashTimeout: 60000, // Increase timeout
          iframeHashTimeout: 6000,
          loadFrameTimeout: 0,
        }
      });
    let initialized = false;

    const initializeMsal = async () => {
      if (!initialized) {
        await msalInstance.initialize();
        initialized = true;
      }
    };
    
    const getSharePointDocument = async (documentUrl: string): Promise<string> => {
      try {
        console.info('Initializing MSAL...');
        await initializeMsal();

        console.info('Starting SharePoint document fetch', { documentUrl });
        // Try silent token acquisition first
        const account = (await msalInstance.getAllAccounts())[0];
        if (!account) {
            console.info('No account found, triggering login...');
            await msalInstance.loginPopup({
              scopes: ["Files.Read", "Sites.Read.All"]
            });
        }

        console.info('Account found, acquiring token');
        const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msalInstance, {
            account: account,
            interactionType: InteractionType.Redirect,
            scopes: ["Files.Read", "Sites.Read.All"]
        });
        
        console.info('Initializing Graph client');
        const graphClient = Client.initWithMiddleware({ authProvider });
  
        // Get direct download URL for the PDF
        console.info('Fetching drive item', { documentUrl });
        const driveItem = await graphClient.api(documentUrl).get();
  
        return driveItem;
      } catch (error) {
        console.error('SharePoint access error:', error);
        throw error;
      }
    };
  
    return { getSharePointDocument };
  };

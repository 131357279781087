// ConsultantPage.tsx
import React from 'react';
import { Grid } from '@material-ui/core';
import {
  EntityLayout,
  EntitySwitch,
  EntityOrphanWarning,
  EntityRelationWarning,
  EntityProcessingErrorsPanel,
  isOrphan,
  hasRelationWarnings,
  hasCatalogProcessingErrors,
} from '@backstage/plugin-catalog';
import {
  EntityUserProfileCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Box, 
  CircularProgress,
  makeStyles,
} from '@material-ui/core';

import { Warning as WarningIcon } from '@material-ui/icons';

import { useSharePoint } from '../../../hooks/useSharePoint';

const useStyles = makeStyles(theme => ({
  iframe: {
    width: '100%',
    height: '600px',
    border: 'none',
  },
  errorCard: {
    backgroundColor: theme.palette.error.light,
  },
  warningCard: {
    backgroundColor: theme.palette.warning.light,
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.warning.main}`,
    borderRadius: theme.shape.borderRadius,
  },
  warningIcon: {
    color: theme.palette.warning.dark,
    fontSize: 40,
    marginBottom: theme.spacing(1),
  },
  warningContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(1),
  },
  warningText: {
    color: theme.palette.text.primary, // Better contrast
  },
}));

export const SharePointPDFViewer: React.FC = () => {
  const classes = useStyles();
  const { entity } = useEntity();
  const config = useApi(configApiRef);
  const displayName = (entity.spec?.profile as { displayName?: string })?.displayName;

  const { getSharePointDocument } = useSharePoint();
  const [pdfUrl, setPdfUrl] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  // Debug entire config
  console.info('Full config:', {
    hasSharePoint: config.has('sharepoint'),
    allKeys: config.keys(),
    rawConfig: config.getOptional('sharepoint'),
  });

  // Try to get config, handle missing case
  let sharePointBaseUrl: string | undefined;
  try {
    // Add explicit config path
    sharePointBaseUrl = config.getConfig('app')?.getOptionalString('sharepoint.baseUrl');
    console.info('SharePoint config:', { sharePointBaseUrl });
  } catch (err) {
    return (
      <Card className={classes.warningCard}>
        <CardContent className={classes.warningContent}>
          <WarningIcon className={classes.warningIcon} />
          <Typography variant="h6" className={classes.warningText} gutterBottom>
            CV Preview Unavailable
          </Typography>
          <Typography variant="body1" className={classes.warningText}>
            SharePoint integration is not configured. Please contact administrator.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  React.useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        if (!displayName) {
          throw new Error('No display name found for user');
        }
        
        const response = await getSharePointDocument(`${sharePointBaseUrl}/${displayName}/latest.pdf`);
        setPdfUrl(response);
      } catch (err) {
        setError('Failed to load PDF document');
        console.error(err);
      }
    };

    fetchPdfUrl();
  }, [entity, displayName, sharePointBaseUrl]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Card className={classes.errorCard}>
        <CardContent>
          <Typography color="error">{error}</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        {pdfUrl && (
          <iframe
            src={`${pdfUrl}?embed=true`}
            className={classes.iframe}
            title="CV Document"
          />
        )}
      </CardContent>
    </Card>
  );
};

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

export const ConsultantPage: React.FC = () => {
  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3}>
          {entityWarningContent}
          <Grid item xs={12} md={6}>
            <EntityUserProfileCard variant="gridItem" />
          </Grid>
          <Grid item xs={12} md={6}>
            <EntityOwnershipCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route path="/cv" title="CV">
        <SharePointPDFViewer />
      </EntityLayout.Route>
  </EntityLayout>
  );
};

export default ConsultantPage;
